import React, { useEffect, useState } from 'react';
import axios from 'axios';
import './RouteViewer.css';

function RouteViewer(props) {
    const [imageUrl, setImageUrl] = useState('');
    const [distance, setDistance] = useState(12.7);
    const [duration, setDuration] = useState(31);
    const [fare, setFare] = useState(16000);
    const requestUrl = "https://telegrambot.bonkab.com/route?origin=37.56274084921693,126.83103840483581&destination=37.5279988990549,126.929215651864"

    useEffect(() => {
        const featchData = async () => {
            try {
                const response = await axios.get(requestUrl);
                setImageUrl(response.data.url);
                setDistance((response.data.distance/1000).toFixed(1));
                setDuration(Math.floor(response.data.duration/60));
                setFare(response.data.fare.taxi);
            } catch (err) {
                // nothing
            }
        };

        featchData();
    }, [requestUrl])

    return (
        <div>
            {imageUrl && 
                <div className='image-container'>
                    <img src={imageUrl} alt="응답 이미지" className='image' />
                    <div className='text-overlay'>
                        <span className='large-text bold-text'>{fare.toLocaleString()}</span>
                        <span className='small-text'> 원</span>
                        <div className='duration'>
                            <span className='bold-text'>{duration}</span> 분<span></span>
                        </div>
                        <div className='distance'>
                            <span className='bold-text'>{distance}</span><span> km</span>
                        </div>
                    </div>
                </div>
            }
        </div>
    );
}

export default RouteViewer;